<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12" md="9" sm="9">
        <v-row>
          <v-col md="10" offset-md="1">
            <table>
              <tr>
                <td>
                  <template v-if="updateEngineer && pageLoading">
                    <v-skeleton-loader
                      class="custom-skeleton custom-avatar-100px"
                      type="avatar"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <v-progress-circular
                      indeterminate
                      :size="100"
                      :width="10"
                      color="cyan"
                      v-if="primaryLoader"
                    ></v-progress-circular>
                    <v-img
                      v-else
                      :lazy-src="$defaultProfileImage"
                      :src="getProfileImage"
                      max-height="100"
                      max-width="100"
                      height="100px"
                      width="100px"
                      class="custom-grey-border custom-border-radius-50"
                    >
                    </v-img>
                  </template>
                </td>
                <td>
                  <template v-if="updateEngineer && pageLoading">
                    <v-skeleton-loader
                      class="mx-4 custom-skeleton small-skeleton-action-button"
                      type="button"
                    >
                    </v-skeleton-loader>
                  </template>
                  <v-btn
                    v-else
                    class="mx-4 custom-bold-button"
                    outlined
                    small
                    :disabled="primaryLoader"
                    :loading="primaryLoader"
                    v-on:click="selectImage"
                    color="cyan"
                  >
                    <template v-if="personalInfo.profile_image"
                      >Change Image</template
                    >
                    <template v-else>Upload Image</template>
                  </v-btn>
                  <div class="d-none">
                    <v-file-input
                      v-model.trim="rawImage"
                      ref="uploadImage"
                      :rules="imageRules"
                      accept="image/png, image/jpeg, image/bmp"
                    ></v-file-input>
                  </div>
                </td>
              </tr>
            </table>
          </v-col>
          <v-col md="10" offset-md="1">
            <v-row>
              <v-col md="4" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 ml-1"
                    >Salutation</label
                  >
                  <!-- :rules="[
                      validateRules.required(personalInfo.title, 'Title'),
                    ]" -->
                  <!-- <v-select
                    v-model.trim="personalInfo.title"
                    :items="salutationList"
                    dense
                    filled
                   
                    item-text="text"
                    item-value="value"
                    item-color="cyan"
                    label="Salutation"
                    solo
                    flat
                    color="cyan"
                  ></v-select> -->
                  <v-autocomplete
                    :items="salutationList"
                    dense.
                    filled
                    color="cyan"
                    item-color="cyan"
                    solo
                    flat
                    v-model.trim="personalInfo.title"
                    placeholder="Select Salutation"
                    item-text="text"
                    item-value="value"
                    class="width-100 new-height"
                    hide-details
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Salutation(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </template>
              </v-col>
              <v-col md="4" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <!-- validateRules.required(
                        personalInfo.first_name,
                        'First name'
                      ), -->
                  <label class="font-weight-700 font-size-16 ml-1"
                    >First name</label
                  >
                  <v-text-field
                    v-model.trim="personalInfo.first_name"
                    :rules="[
                      validateRules.minLength(
                        personalInfo.first_name,
                        'First name',
                        1
                      ),
                      validateRules.maxLength(
                        personalInfo.first_name,
                        'First name',
                        50
                      ),
                    ]"
                    dense
                    filled
                    label="First name"
                    solo
                    flat
                  ></v-text-field>
                </template>
              </v-col>
              <v-col md="4" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 ml-1"
                    >Last name</label
                  >
                  <v-text-field
                    v-model.trim="personalInfo.last_name"
                    :rules="[
                      validateRules.minLength(
                        personalInfo.last_name,
                        'Last name',
                        1
                      ),
                      validateRules.maxLength(
                        personalInfo.last_name,
                        'Last name',
                        50
                      ),
                    ]"
                    dense
                    filled
                    label="Last name"
                    solo
                    flat
                  ></v-text-field>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 required ml-1"
                    >Display name</label
                  >
                  <v-text-field
                    v-model.trim="personalInfo.display_name"
                    :rules="[
                      validateRules.required(
                        personalInfo.display_name,
                        'Display name'
                      ),
                      validateRules.minLength(
                        personalInfo.display_name,
                        'Display name',
                        1
                      ),
                      validateRules.maxLength(
                        personalInfo.display_name,
                        'Display name',
                        50
                      ),
                    ]"
                    dense
                    filled
                    label="Display name"
                    solo
                    flat
                  ></v-text-field>
                </template>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 ml-1">
                    Designation</label
                  >
                  <v-autocomplete
                    :items="engineerDesignations"
                    dense.
                    filled
                    color="cyan"
                    item-color="cyan"
                    solo
                    flat
                    v-model.trim="personalInfo.designation"
                    append-outer-icon="mdi-cog"
                    placeholder="Select Designation"
                    v-on:click:append-outer="Designationdata"
                    item-text="text"
                    item-value="value"
                    class="width-100 new-height"
                    hide-details
                  >
                    <!-- :rules="[
                      validateRules.required(
                        personalInfo.designation,
                        'Designationdata'
                      ),
                    ]" -->
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Designation(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>

                  <template>
                    <ManageEdesignation
                      :d-dialog="manageBrandDialog"
                      :e_designation="engineerDesignations"
                      v-on:close-dialog="manageBrandDialog = false"
                      :exist-category="personalInfo.designation"
                      v-on:get-e-designation="getOptions"
                    ></ManageEdesignation>
                  </template>

                  <!-- <v-select
                    :items="engineerDesignations"
                    v-model.trim="personalInfo.designation"
                    :rules="[
                      validateRules.required(
                        personalInfo.designation,
                        'Designation'
                      ),
                    ]"
                    dense
                    filled
                    label="Designation"
                    solo
                    flat
                    item-color="cyan"
                    item-text="title"
                    item-value="value"
                  ></v-select> -->
                </template>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 ml-1"
                    >Skills</label
                  >
                  <!-- :rules="[
                      validateRules.required(personalInfo.skills, 'Skills'),
                    ]" -->
                  <v-select
                    :items="engineerSkills"
                    v-model.trim="personalInfo.skills"
                    dense
                    filled
                    label="Skills"
                    solo
                    flat
                    item-color="cyan"
                    item-text="title"
                    item-value="value"
                    multiple
                  ></v-select>
                </template>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 required ml-1"
                    >Email address</label
                  >
                  <v-text-field
                    v-model.trim="personalInfo.primary_email"
                    :rules="[
                      validateRules.required(
                        personalInfo.primary_email,
                        'Email address'
                      ),
                      validateRules.validEmail(
                        personalInfo.primary_email,
                        'Email address'
                      ),
                      validateRules.minLength(
                        personalInfo.primary_email,
                        'Email address',
                        1
                      ),
                      validateRules.maxLength(
                        personalInfo.primary_email,
                        'Email address',
                        50
                      ),
                    ]"
                    dense
                    filled
                    label="Email address"
                    solo
                    flat
                  ></v-text-field>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 ml-1"
                    >Phone number</label
                  >
                  <PhoneTextField
                    id="primary_phone"
                    v-model="personalInfo.primary_phone"
                    :value="personalInfo.primary_phone"
                  >
                  </PhoneTextField>
                  <!-- required -->
                </template>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16 ml-1">Role</label>
                  <!-- :rules="[validateRules.required(personalInfo.role, 'Role')]" -->

                  <v-select
                    :items="roles"
                    v-model.trim="personalInfo.role"
                    dense
                    filled
                    label="Role"
                    solo
                    flat
                    item-color="cyan"
                    item-text="name"
                    item-value="id"
                    :disabled="true"
                  ></v-select>
                </template>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-width-100px custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <v-switch
                  v-else
                  v-model.trim="personalInfo.can_login"
                  inset
                  :value="1"
                  color="cyan"
                  hide-details
                  label="Allow Login"
                  class="mt-0"
                ></v-switch>
              </v-col>
              <v-col v-if="false" md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-width-100px custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <v-switch
                  v-else
                  v-model.trim="personalInfo.allow_leave"
                  inset
                  :value="1"
                  color="cyan"
                  hide-details
                  label="Allow Leave"
                  class="mt-0"
                ></v-switch>
              </v-col>
              <v-col md="6" class="py-0">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-width-100px custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <!-- <v-switch
                  v-else
                  v-model.trim="personalInfo.is_driver"
                  inset
                  :value="1"
                  color="cyan"
                  hide-details
                  label="Is Driver"
                  class="mt-0"
                ></v-switch> -->
              </v-col>
              <!-- <v-col
                md="12"
                class="py-4"
                v-if="personalInfo.allow_leave == 1 && leaveTypes.length"
              >
                <table width="100%">
                  <tbody>
                    <tr v-for="(row, index) in leaveTypes" :key="index">
                      <td
                        class="font-size-16 font-weight-600 px-2 py-4"
                        valign="middle"
                      >
                        {{ row.title }}
                      </td>
                      <td class="py-2">
                        <v-text-field
                          v-model.trim="personalInfo.engineerLeaves[row.value]"
                          v-mask="'###'"
                          dense
                          hide-details
                          filled
                          :label="row.title"
                          solo
                          flat
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="custom-sidebar-container white lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 100%;
}
.v-input input {
  max-height: 30px;
}
</style>
<script>
import ManageEdesignation from "@/view/pages/partials/Manage-Edesignation.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import { EngineerEventBus } from "@/core/lib/engineer/engineer.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import { GET } from "@/core/services/store/request.module";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";

export default {
  name: "personal-info",
  mixins: [ValidationMixin, CommonMixin, FileManagerMixin],
  props: {
    pageLoading: {
      type: Boolean,
      default: false,
    },
    updateEngineer: {
      type: Boolean,
      default: false,
    },
    engineer: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      engineerSkills: [],
      manageBrandDialog: false,
      engineerDesignations: [],
      e_designation: [],
      leaveTypes: [],
      timeoutLimit: 100,
      roles: [],
      timeout: null,
      personalInfo: {
        profile_image: null,
        title: null, //"mr",
        first_name: null,
        last_name: null,
        display_name: null,
        designation: 0,
        primary_email: null,
        primary_phone: null,
        engineerLeaves: {},
        skills: [],
        leave: null,
        can_login: 1,
        allow_leave: 0,
        is_driver: 0,
        role: 2,
      },
      rawImage: null,
      fieldDescriptions: [],
    };
  },
  watch: {
    "personalInfo.first_name"() {
      this.personalInfo.display_name =
        (this.personalInfo.first_name || "") +
        " " +
        (this.personalInfo.last_name || "");
    },
    "personalInfo.last_name"() {
      this.personalInfo.display_name =
        (this.personalInfo.first_name || "") +
        " " +
        (this.personalInfo.last_name || "");
    },
    rawImage(param) {
      const _this = this;
      _this.uploadAttachment(param).then((response) => {
        _this.personalInfo.profile_image = _this.lodash.head(response);
      });
    },
    personalInfo: {
      deep: true,
      immediate: true,
      handler(param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          EngineerEventBus.$emit("update:personal-info", param);
        }, _this.timeoutLimit);
      },
    },
    engineer() {
      this.setPersonalInfo();
    },
  },
  methods: {
    Designationdata() {
      this.manageBrandDialog = true;
      this.getOptions();
    },

    getRolesNew() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "user-role-new" })
        .then(({ data }) => {
          _this.roles = data;
          // console.log(_this.roles, "hello");
        })
        .catch((error) => {
          _this.logError(error);
        });
    },

    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "engineer/options",
        })
        .then(({ data }) => {
          _this.engineerDesignations = this.lodash.map(
            data.engineer_designation,
            function (row) {
              return {
                text: row.text,
                value: row.id,
              };
            }
          );
          _this.leaveTypes = this.lodash.map(data.leave_types, function (row) {
            return {
              title: row.value,
              value: row.id,
            };
          });
          _this.engineerSkills = this.lodash.map(data.skills, function (row) {
            return {
              title: row.value,
              value: row.value.toLowerCase(),
            };
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    setPersonalInfo() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineer) === false) {
        _this.personalInfo.profile_image = _this.engineer.profile_logo;
        _this.personalInfo.title = _this.engineer.title;
        _this.personalInfo.first_name = _this.engineer.first_name;
        _this.personalInfo.last_name = _this.engineer.last_name;
        _this.personalInfo.role = _this.engineer.role.id;
        _this.personalInfo.display_name = _this.engineer.display_name;
        if (_this.engineer.designation && _this.engineer.designation.id) {
          _this.personalInfo.designation = _this.engineer.designation.id;
        }
        _this.personalInfo.skills = _this.engineer.skills;
        _this.personalInfo.primary_email = _this.engineer.primary_email;
        _this.personalInfo.primary_phone = _this.engineer.primary_phone;
        _this.personalInfo.leave = _this.engineer.leave;
        _this.personalInfo.can_login = +_this.engineer.can_login;
        _this.personalInfo.allow_leave = +_this.engineer.allow_leave;
        _this.personalInfo.is_driver = +_this.engineer.is_driver;
        if (_this.engineer.leaves && _this.engineer.leaves.length > 0) {
          for (let i = 0; i < _this.engineer.leaves.length; i++) {
            const { leave, leave_allowed } = _this.engineer.leaves[i];
            _this.personalInfo.engineerLeaves[leave.id] = leave_allowed;
          }
        }
      }
    },
  },
  computed: {
    getProfileImage() {
      return this.lodash.isEmpty(this.personalInfo.profile_image) === false
        ? this.personalInfo.profile_image.file.url
        : null;
    },
  },
  components: {
    PhoneTextField,
    ManageEdesignation,
  },
  mounted() {
    this.setPersonalInfo();
    this.getOptions();
    this.getRolesNew();
  },
};
</script>
